import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  icon: {
    width: '1rem',
    height: '1rem',
  },
  resetFlagButton: {
    width: 'fit-content',
    marginInline: 'auto',
  },
}));
