/* eslint-disable no-underscore-dangle */

import { LOCATION_CHANGE } from 'connected-react-router';
import type { Action } from 'redux';
import type { ReduxState } from '../reducers';
import { getSessionID } from '../utilities/sessionID';
import { pathsDynamic, pathsExact } from './analyticsRoutes';

type PageInfoType = {
  account_type?: string;
  pagekind?: string;
  pagefunction?: string;
  pagesubfunction?: string;
  pagename?: string;
  attr?: string;
  transmitStatus?: string;
};

const pageAnalytics = {
  globalWindow: window || global,
  locked: false,

  initActions: [LOCATION_CHANGE],

  initSFDDL() {
    if (!this.globalWindow._SFDDL) this.globalWindow._SFDDL = { ready: false };

    this.globalWindow._SFDDL.base = {
      app_version: '2.23.0',
      event_type: 'pageview',
      site_identifier: 'Web',
      app_type: 'webapp',
      app_store: '',
      country: 'us',
      language: 'en',
      session_id: getSessionID(),
      client_name: 'syncbank',
      amount: '',
      existing_user: '',
    };
    this.globalWindow._SFDDL.pageInfo = {};
  },

  newPageView(initialDataObject?: PageInfoType) {
    if (!this.globalWindow._SFDDL) this.initSFDDL();
    this.globalWindow._SFDDL.pageInfo = {
      account_type: '',
      pagekind: '',
      pagefunction: '',
      pagesubfunction: '',
      pagename: '',
      attr: '',
      transmitStatus: '',
    };
    global._SFDDL.ready = false;
    this.locked = false;
    if (initialDataObject) this.addInfo(initialDataObject);
    return this;
  },

  record() {
    if (this.locked) return;

    const eventName = 'customPageview';
    let pageTrackingEvent;
    if (typeof Event === 'function') {
      pageTrackingEvent = new Event(eventName);
    } else {
      pageTrackingEvent = document.createEvent('Event');
      pageTrackingEvent.initEvent(eventName, false, false);
    }
    global._SFDDL.ready = true;
    if (process.env.NODE_ENV === 'development') {
      /* eslint-disable no-console */
      console.log('Page View recorded:');
      (console.table || console.log)({ ...this.globalWindow._SFDDL.pageInfo });
      /* eslint-enable no-console */
    }
    if (!this.globalWindow._SFDDL.debugging && document.dispatchEvent)
      document.dispatchEvent(pageTrackingEvent);
    this.locked = true;
  },

  recordNonTrackedPage() {
    // flows that are not tracked, but are part of an end point that is, must be explicitly set for QA or the ready state wil be false
    if (this.locked) return;
    global._SFDDL.ready = true;
    this.locked = true;
  },

  getPageInfo(pageInfoName: string) {
    return this.globalWindow._SFDDL.pageInfo[pageInfoName];
  },

  getPageInfoObject() {
    return this.globalWindow._SFDDL.pageInfo;
  },

  addInfo(pageDataObject: PageInfoType) {
    if (this.locked) return this;
    if (!this.globalWindow._SFDDL) this.initSFDDL();
    this.globalWindow._SFDDL.pageInfo = { ...this.globalWindow._SFDDL.pageInfo, ...pageDataObject };
    return this;
  },

  setAuthState(state: ReduxState) {
    this.globalWindow._SFDDL.base.session_id = getSessionID();
    this.globalWindow._SFDDL.base.existing_user = state.authenticate.isLoggedIn ? 'Y' : 'N';
  },

  setApplicationState(state: ReduxState) {
    if (!this.globalWindow._SFDDL.base) return;

    const prevTrackingId = this.globalWindow._SFDDL.base.application_tracking_id;
    const { trackingId } = state.applications;

    // Update 'application_tracking_id' if truthy value differs.
    // Note that the value lives in analytics even if cleared from the store.
    // This allows the analytics team to track users outside of the AO flow.
    if (trackingId && trackingId !== prevTrackingId) {
      this.globalWindow._SFDDL.base.application_tracking_id = trackingId;
    }

    // Set 'application_id' if available. Is set after NAO/EAO application
    // submission success.
    // Why is this under 'base' and not 'pageInfo'? The analytics folks are
    // unresponsive and we're trying to get a release out soon. :(
    const prevAppId = this.globalWindow._SFDDL.base.application_id;
    const { lastApplicationResponse } = state.applications;
    const appId = lastApplicationResponse && lastApplicationResponse.applicationId;
    if (appId && appId !== prevAppId) {
      this.globalWindow._SFDDL.base.application_id = appId;
    }
  },

  processAction(nextAction: Action, state: ReduxState) {
    const { pathname } = state.router.location;

    if (this.initActions.includes(nextAction.type)) this.newPageView();

    let callbacksForThisPage = pathsExact[pathname];

    if (!callbacksForThisPage) {
      // if no callbacks were found for this page then scan the dynamic paths for a match
      pathsDynamic.some((pathObj) => {
        if (pathObj.validator(pathname)) {
          callbacksForThisPage = pathObj.callbacks;
          return true;
        }
        return false;
      });
    }

    if (callbacksForThisPage) {
      if (callbacksForThisPage[nextAction.type]) {
        this.setAuthState(state);
        callbacksForThisPage[nextAction.type](nextAction, state); // process callback
      }
    } else if (this.initActions.includes(nextAction.type)) {
      // pages we have not yet implemented analytics on, set ready state to true for TE to trigger page loaded event
      global._SFDDL.ready = true;
      this.locked = true;
    }

    this.setApplicationState(state);
  },
} as const;

export const pageAnalyticsMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    const nextAction = next(action);
    pageAnalytics.processAction(nextAction, getState());
    return nextAction;
  };

export default pageAnalytics;
