import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from 'src/reducers';
import type { Product, Term } from '../../containers/products/products.reducer';
import { TextButton } from '../buttons/buttons';
import RatesAndTermsDialog from './ratesAndTermsDialog';
import { clickTrack } from '../../analytics/clickTracking.constants';

type Props = {
  children?: React.ReactNode;
  displayCode?: string;
};

type StateProps = {
  products: Product[];
  terms: Term[];
  isAffinityCustomer: boolean;
  affinityVerbiage: string | null | undefined;
  affinityCompanyCode: string;
};

type AllProps = Props & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  products: state.products.productTypes.products,
  terms: state.products.productTypes.terms,
  isAffinityCustomer: state.products.affinity,
  affinityVerbiage: state.products.affinityVerbiage,
  affinityCompanyCode:
    state.applications.customerInfo && state.applications.customerInfo.affinityCompanyCode,
});
const RatesAndTermsButton = ({
  children,
  displayCode,
  products,
  terms,
  isAffinityCustomer,
  affinityVerbiage,
  affinityCompanyCode,
}: AllProps) => {
  const [open, setOpen] = useState(false);
  const filteredProducts = displayCode
    ? products.filter((product) => product.displayCode === displayCode)
    : products;

  return (
    <>
      <TextButton
        style={{ textAlign: 'left' }}
        data-test="link-product-rates"
        data-track-title={clickTrack.selectAProduct.rates}
        data-track-type="link"
        onClick={() => setOpen(true)}
        type="button"
      >
        {children}
      </TextButton>
      <RatesAndTermsDialog
        open={open}
        handleClose={() => setOpen(false)}
        affinityVerbiage={affinityVerbiage}
        isAffinityCustomer={isAffinityCustomer}
        affinityCompanyCode={affinityCompanyCode}
        products={filteredProducts}
        terms={terms}
      />
    </>
  );
};

export default connect(mapStateToProps)(RatesAndTermsButton);
