import type { ExternalAccount } from 'Domain/Account/ExternalAccount';
import type { AddExternalAccountForm } from '../../utilities/types';
import axios from '../../utilities/axios';
import {
  EXTERNAL_ACCOUNT_URL,
  EXTERNAL_ACCOUNTS_URL,
  RETRIEVE_PUBLIC_KEY_URL,
} from '../../utilities/route-mappings';
import type { AddExternalAccountResponse } from '../addExternalAccount/externalAccount.domains';
import type { FetchEncryptionKeyResponse } from '../../utilities/crypt';

export type FetchEncryptionKeyService = () => Promise<FetchEncryptionKeyResponse>;

export type FetchExternalAccountsService = (arg1?: undefined) => Promise<ExternalAccount[]>;

export const fetchExternalAccountsService: FetchExternalAccountsService = async () => {
  const response = await axios.get(EXTERNAL_ACCOUNTS_URL(), {
    params: {
      purpose: 'dashboard',
    },
  });
  return response.data;
};

export const deleteExternalAccountService = (accountId: string): Promise<Record<string, string>> =>
  axios.delete(EXTERNAL_ACCOUNT_URL(accountId));

export type DeleteExternalAccountService = typeof deleteExternalAccountService;

export const fetchEncryptionKeyService = () =>
  axios.get(RETRIEVE_PUBLIC_KEY_URL()).then((res) => res.data);

export type AddExternalAccountService = (
  arg1: AddExternalAccountForm
) => Promise<AddExternalAccountResponse>;

export const addExternalAccountService: AddExternalAccountService = ({
  routingNumber,
  accountNumber,
  accountType,
  confirmedACH,
}) =>
  axios
    .post(EXTERNAL_ACCOUNTS_URL(), {
      routingNumber,
      accountNumber,
      accountType,
      confirmedACH,
    })
    .then((res) => res.data);
