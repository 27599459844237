import { formValueSelector } from 'redux-form';
import branch from 'branch-sdk';
import type { ReduxState } from '../../reducers';
import pageAnalytics from '../pageAnalytics';
import type { SubmitApplicationAnalyticsAction } from '../actions';
import {
  ANALYTICS_ADD_JOINT_OWNER_MODAL,
  ANALYTICS_ELIGIBILITY_ERROR_AO,
  ANALYTICS_MORE_ABOUT_YOU,
  ANALYTICS_REVIEW_JOINT_OWNER_MODAL,
  ANALYTICS_REVIEW_SELECTION,
  ANALYTICS_REVIEW_TERMS,
  ANALYTICS_REVIEW_TERMS_TRANSMIT,
  ANALYTICS_REVIEW_ETIN_TRANSMIT,
  ANALYTICS_SELECT_PRODUCT,
  ANALYTICS_SELECTED_JOINT_OWNER,
  ANALYTICS_SUBMIT_APPLICATION_ERROR,
} from '../actions';
import type { OpenAccountAction } from '../../containers/newAccountOpening/newUser/openAccount.reducer';
import { EAO_FORM_ID, NAO_FORM_ID } from '../../utilities/accountOpeningFlowType';
import pageTrack from '../pageAnalytics.constants';
import { TRANSMIT_ANALYTICS_RESPONSE } from '../../containers/otp/otp.constants';

const initData = (state: ReduxState) => {
  return {
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_opening,
    pagesubfunction: state.authenticate.isLoggedIn
      ? pageTrack.pagesubfunction.EAO
      : pageTrack.pagesubfunction.NAO,
  };
};

const viewMoreAboutYou = () => {
  pageAnalytics
    .newPageView({
      account_type: pageTrack.account_type.generic,
      pagekind: pageTrack.pagekind.account_opening,
      pagename: pageTrack.pagename.step2,
      pagesubfunction: pageTrack.pagesubfunction.NAO,
      pagefunction: pageTrack.pagefunction.about_you,
    })
    .record();
};

const viewProductSelection = (action: OpenAccountAction, state: ReduxState) => {
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: pageTrack.pagename.start,
      pagefunction: pageTrack.pagefunction.choose_product,
    })
    .record();
  // below code to send event to Branch
  if (
    window.__config__.BRANCH_EVENTS_ENABLED === 'true' &&
    state.authenticate &&
    state.authenticate.isLoggedIn
  ) {
    // we never know what this data will be
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const customData: Record<string, any> = {};
    try {
      branch.init(window.__config__.BRANCH_KEY);
      branch.logEvent(
        'existing_customer_new_account_opening_pageview',
        customData,
        function logError(err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }
};

const viewAddJointOwners = (action: OpenAccountAction, state: ReduxState) => {
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: state.flashMessage.modalFlashMessage.messageText
        ? pageTrack.pagename.step2_personal_details_server_error
        : pageTrack.pagename.start,
      pagefunction: pageTrack.pagefunction.joint_owner_new,
    })
    .record();
};

const viewReviewJointOwners = (action: OpenAccountAction, state: ReduxState) => {
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: pageTrack.pagename.personal_info,
      pagefunction: pageTrack.pagefunction.joint_owner_new,
    })
    .record();
};

const viewJointOwners = (action: OpenAccountAction, state: ReduxState) => {
  let pagenameState = pageTrack.pagename.complete;
  if (action.payload === 0) pagenameState = pageTrack.pagename.view;
  else if (action.payload === -1) pagenameState = pageTrack.pagename.error_cant_add;
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: pagenameState,
      pagefunction: pageTrack.pagefunction.joint_owner,
    })
    .record();
};

const viewReviewSelection = (action: OpenAccountAction, state: ReduxState) => {
  const prodSelection =
    formValueSelector(EAO_FORM_ID)(state, 'product.productType') ||
    formValueSelector(NAO_FORM_ID)(state, 'product.productType');
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: pageTrack.pagename.complete,
      pagefunction: pageTrack.pagefunction.choose_product,
      attr: prodSelection,
    })
    .record();
};

const viewTerms = (action: OpenAccountAction, state: ReduxState) => {
  const { terms } = state.applications;
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: pageTrack.pagename.start,
      pagefunction: pageTrack.pagefunction.review,
      attr: terms?.etin?.length ? pageTrack.attr.etin : pageTrack.attr.no_etin,
    })
    .record();
};

const viewEtin = (action: OpenAccountAction, state: ReduxState) => {
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: pageTrack.pagename.start,
      pagefunction: pageTrack.pagefunction.review,
      transmitStatus: TRANSMIT_ANALYTICS_RESPONSE.STATUS_SUCCESS,
      attr: pageTrack.attr.etin,
    })
    .record();
};

const viewTermsTransmit = (action: OpenAccountAction, state: ReduxState) => {
  pageAnalytics
    .newPageView(initData(state))
    .addInfo({
      pagename: pageTrack.pagename.terms,
      pagefunction: pageTrack.pagefunction.review,
      attr: pageTrack.attr.no_etin,
    })
    .record();
};

const submitApplicationError = (action: SubmitApplicationAnalyticsAction, state: ReduxState) => {
  let pageName;
  if (parseInt(action.payload, 10) >= 500) {
    pageName = pageTrack.pagename.server_error;
  } else if (parseInt(action.payload, 10) >= 400) {
    pageName = pageTrack.pagename.application_failed_server_error;
  } else {
    pageName = '';
  }

  if (pageName) {
    pageAnalytics
      .newPageView(initData(state))
      .addInfo({
        pagename: pageName,
        pagefunction: pageTrack.pagefunction.review,
      })
      .record();
  }
};

const eligibilityCallError = () => {
  pageAnalytics
    .newPageView({
      account_type: pageTrack.account_type.generic,
      pagekind: pageTrack.pagekind.account_opening,
      pagesubfunction: pageTrack.pagesubfunction.NAO,
      pagename: pageTrack.pagename.eligibility_server_error,
      pagefunction: pageTrack.pagefunction.ao_otp,
    })
    .record();
};

export const openAccountFormFlowEvents = {
  [ANALYTICS_MORE_ABOUT_YOU]: viewMoreAboutYou,
  [ANALYTICS_SELECTED_JOINT_OWNER]: viewJointOwners,
  [ANALYTICS_SELECT_PRODUCT]: viewProductSelection,
  [ANALYTICS_ADD_JOINT_OWNER_MODAL]: viewAddJointOwners,
  [ANALYTICS_REVIEW_JOINT_OWNER_MODAL]: viewReviewJointOwners,
  [ANALYTICS_REVIEW_SELECTION]: viewReviewSelection,
  [ANALYTICS_REVIEW_TERMS]: viewTerms,
  [ANALYTICS_REVIEW_ETIN_TRANSMIT]: viewEtin,
  [ANALYTICS_REVIEW_TERMS_TRANSMIT]: viewTermsTransmit,
  [ANALYTICS_SUBMIT_APPLICATION_ERROR]: submitApplicationError,
  [ANALYTICS_ELIGIBILITY_ERROR_AO]: eligibilityCallError,
} as const;

export default openAccountFormFlowEvents;
