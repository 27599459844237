import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StandardTextEmphasis, SubtitleText } from '../typography/typography';
import { NO_EMAIL_ON_FILE } from '../cms/blockText.constants';
import ArrowLink from '../links/arrowLink';
import Routes from '../../containers/routes/routes.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import { AccountActivityBtn } from '../cms/buttonText.constants';
import pxToRem from '../../utilities/pxToRem';
import type { StyleProps } from '../../utilities/types';

const styles = {
  whiteSpaceContainer: {
    paddingRight: pxToRem(5),
  },
} as const;

type Props = {
  dataTest: string;
  hasNoLabel?: boolean;
  emailAddress?: string;
} & StyleProps;

const renderNoEmail = (classes: string) => {
  return (
    <>
      <SubtitleText className={classes} data-test="no-email-on-file-text">
        {NO_EMAIL_ON_FILE}{' '}
      </SubtitleText>
      <ArrowLink
        to={Routes.PROFILE}
        data-test="quick-links-add-email-address"
        data-track-title={clickTrack.profile.add_email_address}
        data-track-type={clickTrackType.LINK}
        role="link"
      >
        {AccountActivityBtn.ADD_EMAIL_ADDRESS}
      </ArrowLink>
    </>
  );
};

const RenderEmailOrNoEmail = ({ dataTest, hasNoLabel, emailAddress, classes }: Props) => {
  return (
    <p data-test={dataTest}>
      {!hasNoLabel && <StandardTextEmphasis>Email: </StandardTextEmphasis>}
      {emailAddress ? (
        <SubtitleText> {emailAddress} </SubtitleText>
      ) : (
        renderNoEmail(classes.whiteSpaceContainer)
      )}
    </p>
  );
};
export default withStyles(styles)(RenderEmailOrNoEmail);
