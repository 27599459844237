import branch from 'branch-sdk';
import pageAnalytics from '../pageAnalytics';

import type { ReduxState } from '../../reducers';
import type { AccountActivityAction } from '../../containers/accountActivity/accountActivity.reducer';
import {
  ACTION_FETCH_ACCOUNT_ID_FAILURE,
  ACTION_FETCH_ACCOUNT_ID_SUCCESS,
} from '../../containers/accountActivity/accountActivity.reducer';
import { isNAOOTPFlow } from '../utilities';
import pageTrack from '../pageAnalytics.constants';
import isFeatureFlagEnabled from '../../utilities/isFeatureFlagEnabled';
import { TRANSMIT_ANALYTICS_RESPONSE } from '../../containers/otp/otp.constants';

const viewComplete = (action: AccountActivityAction, state: ReduxState) => {
  const { lastApplicationResponse } = state.applications;
  const hasCertified = lastApplicationResponse?.primaryCustomer?.certified || false;
  let pageName;
  if (isNAOOTPFlow(state)) {
    pageName = hasCertified ? 'complete' : 'complete_no_otp';
  } else {
    pageName = 'complete';
  }
  const transmitEnabled = isFeatureFlagEnabled('TRANSMIT_ENABLED');

  pageAnalytics
    .newPageView({
      account_type: 'generic',
      pagekind: 'account_opening',
      pagefunction: 'review',
      pagesubfunction: state.authenticate.isLoggedIn
        ? pageTrack.pagesubfunction.EAO
        : pageTrack.pagesubfunction.NAO,
      pagename: pageName,
      attr: pageTrack.attr.blank,
      transmitStatus: transmitEnabled ? TRANSMIT_ANALYTICS_RESPONSE.STATUS_SUCCESS : '',
    })
    .record();

  // below code to send event to Branch
  if (window.__config__.BRANCH_EVENTS_ENABLED === 'true') {
    const appId = lastApplicationResponse && lastApplicationResponse.applicationId;
    const productType =
      lastApplicationResponse &&
      lastApplicationResponse.accounts &&
      lastApplicationResponse.accounts[0] &&
      lastApplicationResponse.accounts[0].product &&
      lastApplicationResponse.accounts[0].product.productDisplayType;
    const branchEventKey = state.authenticate.isLoggedIn
      ? 'existing_customer_new_account_opening_complete'
      : 'new_customer_new_account_opening_complete';
    const customData = {
      product_type: productType && productType.toLowerCase(),
      application_id: appId,
    } as const;

    try {
      branch.init(window.__config__.BRANCH_KEY);
      branch.logEvent(branchEventKey, customData, function (err) {
        console.log(err);
      });
    } catch (err) {
      console.log(err);
    }
  }
};

export const successConfirmationPageEvents = {
  [ACTION_FETCH_ACCOUNT_ID_SUCCESS]: viewComplete,
  [ACTION_FETCH_ACCOUNT_ID_FAILURE]: viewComplete,
} as const;

export default successConfirmationPageEvents;
