const maxExternalAccounts = 5;
export default Object.freeze({
  Text: {
    CTA_TEXT: 'Enter your bank information',
    FORM_DESCRIPITON:
      'Provide the routing and account number for your external account. You must be an owner of the account you are adding, and it may not be a business account.',
    FORM_TITLE: 'Add a new external account',
    MAX_ACCOUNTS_ERROR: `Sorry, you cannot add more than ${maxExternalAccounts} external accounts.`,
  },
  NUM_OF_MAX_ACCOUNTS: maxExternalAccounts,
  Test: {
    FORM_TITLE: 'add-external-bank-title',
    FORM_DESCRIPTION: 'add-external-bank-description',
  },
  RESTRICTED_EXTERNAL_ACCOUNT_INFO: 'You can’t add external accounts at this time.',
});
