import React, { useState } from 'react';
import { Drawer, FormControlLabel, IconButton, Switch } from '@material-ui/core';
import Button from 'bank-component-library/ui/atoms/Button';
import { FeatureFlag, FeatureFlags } from '../../utilities/isFeatureFlagEnabled';
import useEffectOnce from '../../utilities/reactHooks';
import useStyles from './FeatureFlagHelper.styles';
import SVGImage from '../svgImage';
import { CloseIconSVG } from '../svg/svg';
import i18n from '../../strings/i18n';

type FeatureFlagValue = 'true' | 'false';

const getFeatureFlags = (): FeatureFlags => {
  let flags: Partial<FeatureFlags> = {};

  Object.entries(window.__config__).forEach(([key, value]: [FeatureFlag, FeatureFlagValue]) => {
    // only get the boolean feature flags
    if (value === 'true' || value === 'false') {
      if (sessionStorage.getItem(key) === null) {
        flags = { ...flags, [key]: value };
      } else {
        flags = { ...flags, [key]: sessionStorage.getItem(key) };
      }
    }
  });
  return flags as FeatureFlags;
};

export default function FeatureFlagHelper() {
  const [minimize, setMinimize] = useState(true);
  const [flags, setFlags] = useState<FeatureFlags>(getFeatureFlags());

  const enabledFlags: FeatureFlag[] = ['EPREFERENCES_NEW_DESIGN_ENABLED', 'TRANSMIT_ENABLED'];

  const classes = useStyles();

  const initializeSessionFlags = () => {
    for (const [key, value] of Object.entries(flags)) {
      if (sessionStorage.getItem(key) === null) {
        sessionStorage.setItem(key, value);
      }
    }
  };

  useEffectOnce(() => {
    initializeSessionFlags();
  });

  const updateFeatureFlag = (flagName: string, value: string) => {
    const newValue = value === 'true' ? 'false' : 'true';
    sessionStorage.setItem(flagName, newValue);
    setFlags({ ...flags, [flagName]: newValue });
  };

  const resetFeatureFlags = () => {
    for (const [key] of Object.entries(flags)) {
      sessionStorage.removeItem(key);
    }

    initializeSessionFlags();
    setFlags(getFeatureFlags());
  };

  const handleClose = () => {
    setMinimize(!minimize);

    // refresh the page to be sure the flag change goes into effect immediately
    window.location.reload();
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '1000000',
      }}
    >
      <Button type="button" onClick={() => setMinimize(!minimize)}>
        {i18n({ actions: 'showFeatureFlags' })}
      </Button>
      <Drawer anchor="right" open={!minimize} onClose={handleClose}>
        <div style={{ paddingBlock: '2rem' }}>
          <div style={{ position: 'fixed', top: '1rem', right: '1rem' }}>
            <IconButton onClick={handleClose} aria-label="Close">
              <SVGImage
                iconComponent={
                  <CloseIconSVG className={classes.icon} aria-hidden="true" role="img" />
                }
              />
            </IconButton>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1fr, auto)',
              gap: '8px',
            }}
          >
            {Object.entries(flags).map(([key, value]: [FeatureFlag, FeatureFlagValue]) => {
              return (
                <FormControlLabel
                  style={{
                    paddingBlock: '.25rem',
                    paddingInlineStart: '1rem',
                    paddingInlineEnd: '3rem',
                    width: 'fit-content',
                  }}
                  key={key}
                  control={
                    <Switch
                      checked={flags[key] === 'true'}
                      onChange={() => updateFeatureFlag(key, flags[key])}
                      name={key}
                    />
                  }
                  label={key}
                  disabled={!enabledFlags.includes(key)}
                />
              );
            })}
          </div>
          <div className={classes.resetFlagButton}>
            <Button size="large" type="button" onClick={resetFeatureFlags}>
              {i18n({ actions: 'resetAllFeatureFlags' })}
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
