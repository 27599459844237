import React from 'react';
import { useHistory } from 'react-router';
import { kebabCase } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { PrimaryButton } from '../buttons/buttons';
import {
  InformationalSectionHeaderBold,
  ScreenReaderText,
  SubtitleText,
} from '../typography/typography';
import type { Product } from '../../containers/products/products.reducer';
import useStyles from './exploreProductTile.styles';
import Routes from '../../containers/routes/routes.constants';
import { isCD } from '../../utilities/accountTypes';
import type { Theme, CmsExploreProductsTile } from '../../utilities/types';
import { ExploreProductsPageText } from '../../containers/exploreProducts/exploreProducts.constants';
import { clickTrack } from '../../analytics/clickTracking.constants';

type Props = {
  product: Product;
  tile: CmsExploreProductsTile;
};

const ExploreProductTile = ({ product, tile }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const useShortCopy = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const showCDText = isCD(product.displayCode);

  const buttonText = useShortCopy ? 'Open Account' : tile.ctaText;

  return (
    <div
      data-test={`${kebabCase(product.displayName)}-title-container`}
      className={classes.productTileContent}
    >
      <InformationalSectionHeaderBold data-test={`${kebabCase(product.displayName)}-title`}>
        {tile.title}
      </InformationalSectionHeaderBold>

      <div className={classes.subTitle} data-test="sub-title">
        {tile.subtitle}
      </div>

      <div className={classes.apy} aria-hidden>
        {showCDText && (
          <span className={classes.apyUpTo} data-test="cd-display-code">
            {ExploreProductsPageText.UP_TO}
          </span>
        )}
        <span
          data-test={`${kebabCase(product.displayName)}-apy-number`}
          className={classes.apyNumber}
        >
          {product.maxAPY.replace('%', '')}
        </span>
        <div
          data-test={`${kebabCase(product.displayName)}-apy-percent-container`}
          className={classes.apyPercentContainer}
        >
          <div
            data-test={`${kebabCase(product.displayName)}-apy-percent`}
            className={classes.apyPercent}
          >
            %
          </div>
          <div data-test={`${kebabCase(product.displayName)}-apy-text`} className={classes.apyApy}>
            {ExploreProductsPageText.APY}
          </div>
        </div>
      </div>
      <ScreenReaderText>
        {showCDText && ExploreProductsPageText.UP_TO} {product.maxAPY} {ExploreProductsPageText.APY}
      </ScreenReaderText>

      <div
        data-test={`${kebabCase(product.displayName)}-no-min-balance-text`}
        className={classes.minBalanceText}
      >
        {tile.minimumBalance}
      </div>

      <div className={classes.perksList}>
        <SubtitleText
          data-test={`${kebabCase(product.displayName)}-perk`}
          component="span"
          className={classes.perksListItem}
        >
          {tile.body}
        </SubtitleText>
      </div>

      <PrimaryButton
        data-test={`${kebabCase(product.displayName)}-button`}
        data-track-title={`${
          clickTrack.products.explore_products
        }_${product.displayCode.toLowerCase()}`}
        onClick={() => {
          history.push(`${Routes.EAO}?productCode=${product.displayCode}`);
        }}
      >
        {buttonText}
      </PrimaryButton>
    </div>
  );
};

export default ExploreProductTile;
