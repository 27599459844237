import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import type { ReduxState } from '../../reducers';
import type { CmsExploreProductsIntro, CmsExploreProductsTile } from '../../utilities/types';
import type { Product } from '../products/products.reducer';
import useStyles from './exploreProducts.container.styles';
import Footer from '../../components/footer/footer';
import { useAlignmentStyles } from '../../styles/layout/alignment.styles';
import Logo from '../../components/logo/logo';
import {
  ExploreProductsSubHeader,
  LargestHeader,
  SectionHeader,
  StandardText,
} from '../../components/typography/typography';
import Colors from '../../components/colors/colors';
import RatesAndTermsButton from '../../components/ratesAndTerms/ratesAndTermsButton';
import fetchAndValidateProductTypes from '../products/products.actions';
import useEffectOnce from '../../utilities/reactHooks';
import AnimatedLoadingOverlay from '../../components/loadingOverlay/animatedLoadingOverlay';
import RatesAndTerms from '../../components/cms/ratesAndTerms.constants';
import { TextButton } from '../../components/buttons/buttons';
import {
  BackNextAndCancelBtn,
  ExploreProductsBtn,
} from '../../components/cms/buttonText.constants';
import ExploreProductTile from '../../components/exploreProductTile/exploreProductTile';
import Error from '../../components/error/error';
import { ExploreProductsPageText } from './exploreProducts.constants';
import {
  ANALYTICS_EXPLORE_PRODUCTS_VIEW_FAILURE,
  ANALYTICS_EXPLORE_PRODUCTS_VIEW_SUCCESS,
} from '../../analytics/actions';
import useOWCSContent from '../owcs/useOWCSContent';
import { OWCSPageNames, OWCSPageSectionsNames } from '../owcs/owcs.constants';
import { getContentDetailsFromOWCS, getSectionsFromPage } from '../owcs/owcs.utils';
import ImagesFileNames from '../../images';
import SVGImage from '../../components/svgImage';

const pageViewActions = Object.freeze({
  viewSuccess: ANALYTICS_EXPLORE_PRODUCTS_VIEW_SUCCESS,
  viewFailure: ANALYTICS_EXPLORE_PRODUCTS_VIEW_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type ProductResponse = {
  products: Product[];
  loading: boolean;
  error: boolean;
};

type StateProps = {
  affinity: boolean;
  affinityVerbiage: string;
  productsRes: ProductResponse;
};

type DispatchProps = {
  fetchProductTypes: () => void;
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => void;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  affinity: state.products.affinity,
  affinityVerbiage: state.products.affinityVerbiage,
  productsRes: {
    error: state.products.error,
    loading: state.products.isLoading,
    products: state.products.productTypes.products,
  },
});

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchProductTypes: () => {
    dispatch(fetchAndValidateProductTypes());
  },
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => dispatch({ type: pageViewAction }),
});

type Props = StateProps & DispatchProps;

const renderRatesAndTermsHeaderSection = (
  intro: CmsExploreProductsIntro,
  classes: ClassNameMap
) => (
  <>
    {intro && intro.title && (
      <LargestHeader data-test="header-find-account" className={classes.header}>
        {intro.title}
      </LargestHeader>
    )}
    {intro && intro.subtitle && (
      <ExploreProductsSubHeader>{intro.subtitle}</ExploreProductsSubHeader>
    )}
  </>
);

const renderAffinitySection = (
  affinity: boolean,
  classes: ClassNameMap,
  affinityVerbiage: string
) =>
  affinity && (
    <div className={classes.affinityContainer} data-test="affinity-container">
      <span className={classes.affinityIconWrapper} data-test="affinity-icon">
        <SVGImage imageName={ImagesFileNames.affinityAlertCircleSvg} ariaHidden="true" />
      </span>
      <StandardText textColor={Colors.white} data-test="affinity-text">
        {affinityVerbiage || RatesAndTerms.AFFINITY_RATES_HEADER}
      </StandardText>
    </div>
  );

const renderRatesAndTermsTilesSection = (
  tiles: CmsExploreProductsTile[],
  productsRes: ProductResponse
) => (
  <Grid container item data-test="product-container" spacing={4}>
    {tiles.map((tile) => {
      const product = productsRes?.products.find(
        ({ displayCode }: Product) => displayCode === tile.displayCode
      );

      if (!product) return null;

      return (
        <Grid
          key={tile.displayCode}
          item
          data-test={`${tile.displayCode}-product-container`}
          xs={12}
          sm={6}
          lg={4}
        >
          <ExploreProductTile product={product} tile={tile} />
        </Grid>
      );
    })}
  </Grid>
);

const loadingIndicatorFlag = (productsRes, owcsRes) => productsRes.loading || owcsRes.loading;

export const ExploreProducts = ({
  affinity,
  affinityVerbiage,
  fetchProductTypes,
  productsRes,
  recordAnalyticsPageView,
}: Props) => {
  const classes = useStyles();
  const alignmentClasses = useAlignmentStyles();
  const owcsRes = useOWCSContent(OWCSPageNames.EXPLORE_PRODUCTS);

  const intro = getContentDetailsFromOWCS<CmsExploreProductsIntro>(
    getSectionsFromPage(OWCSPageSectionsNames.EXPLORE_PRODUCTS_INTRO, owcsRes.sections)
  )[0];

  const tiles = getContentDetailsFromOWCS<CmsExploreProductsTile>(
    getSectionsFromPage(OWCSPageSectionsNames.EXPLORE_PRODUCTS_CARDS, owcsRes.sections)
  );

  const hasOWCSContent = intro && tiles.length;
  const hasProducts = productsRes.products.length > 0;

  const isLoading = loadingIndicatorFlag(productsRes, owcsRes);
  const hasError = productsRes.error || owcsRes.error;

  useEffectOnce(() => {
    fetchProductTypes();
  });

  useEffect(() => {
    if (!isLoading) {
      if (hasError) {
        recordAnalyticsPageView(pageViewActions.viewFailure);
      }
      if (hasOWCSContent && hasProducts && !hasError) {
        recordAnalyticsPageView(pageViewActions.viewSuccess);
      }
    }
  }, [isLoading, hasProducts, hasOWCSContent, hasError]);

  return (
    <>
      <Helmet>
        <title data-test="page-title">{ExploreProductsPageText.PAGE_TITLE}</title>
      </Helmet>
      <Grid
        container
        direction="column"
        className={classnames(classes.root, { [classes.splitBackground]: !hasError })}
      >
        <Grid item className={alignmentClasses.headerPadding}>
          <Logo isDark={hasError} />
        </Grid>
        <AnimatedLoadingOverlay isLoading={isLoading} />

        <Grid item className={classes.mainContent} component="main">
          {hasError ? (
            <Error center onClick={fetchProductTypes} />
          ) : (
            <>
              {renderRatesAndTermsHeaderSection(intro, classes)}

              {renderAffinitySection(affinity, classes, affinityVerbiage)}

              {renderRatesAndTermsTilesSection(tiles, productsRes)}

              <div data-test="rate-terms-container" className={classes.ratesAndTerms}>
                <RatesAndTermsButton>{ExploreProductsBtn.RATES_AND_TERMS}</RatesAndTermsButton>
                <SectionHeader
                  data-test="apy-disclosure"
                  className={classes.apyDisclosure}
                  component="div"
                >
                  *{RatesAndTerms.APY_DISCLOSURE}
                </SectionHeader>
              </div>
              <TextButton
                data-test="account-cancel-button"
                className={classes.cancelButton}
                onClick={() => {
                  window.history.back();
                }}
              >
                {BackNextAndCancelBtn.CANCEL_GENERIC}
              </TextButton>
            </>
          )}
        </Grid>

        <Grid item>
          <Footer isLight />
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreProducts);
