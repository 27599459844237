import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import type { ReduxState } from '../../reducers/index';
import type { InitiateOTPForm } from '../../utilities/types';
import { useEffectOnce } from '../../utilities/reactHooks';
import type { OTPPhone, SecurityEligibilityFlow } from '../security/security.service';
import PageWithCard from '../../components/page/pageWithCard';
import {
  OTPText,
  initiateOtpRadioOptions,
  OTPInitiateSendValues,
  InitiateOTPFieldNames,
} from './otp.constants';
import { SubtitleText } from '../../components/typography/typography';
import BackAndNext from '../../components/buttons/backAndNext';
import { BackNextAndCancelBtn } from '../../components/cms/buttonText.constants';
import { RenderTelephoneField, RadioField } from '../../components/finalForm/finalFormInputs';
import { initiateOTP } from '../security/security.actions';
import ConfirmLeaveOtpModal from './ConfirmLeaveOtpModal';
import Routes from '../routes/routes.constants';
import { ACTION_OTP_INITIATE } from '../accountDashboard/profile.reducer';
import {
  composeValidators,
  checkEmptyTextField,
  checkValidPhoneNumber,
} from '../../utilities/validators';
import {
  ANALYTICS_INITIATE_OTP_NO_PHONE,
  ANALYTICS_INITIATE_OTP_START_SUCCESS,
} from '../../analytics/actions';
import useOtpStyles from './otp.styles';
import { normalizeDashes } from '../../utilities/reduxForm';
import { SecurityEligibilityFlows } from '../security/security.service';
import { FormHeaderProps } from '../../components/formWithImage/typings';

type StateProps = {
  otpPhones?: OTPPhone[];
  securityEligibilityFlow?: SecurityEligibilityFlow;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  otpPhones: state.profile.otpPhones,
  securityEligibilityFlow: state.profile.securityEligibilityFlow,
});

type DispatchProps = {
  submitInitiateOTP: (arg1: InitiateOTPForm) => Promise<Record<string, string>>;
  recordAnalyticsPageView: () => void;
  recordAnalyticsNoPhonePageView: () => void;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  submitInitiateOTP: (initiateOTPForm) => {
    dispatch({
      type: ACTION_OTP_INITIATE,
      payload: {
        selectedOtpPhoneNumber: initiateOTPForm[InitiateOTPFieldNames.PHONE_NUMBER],
        selectedOtpSendMethod: initiateOTPForm[InitiateOTPFieldNames.HOW_TO_SEND_CODE],
        isSelectedNewPhoneNumber: initiateOTPForm[InitiateOTPFieldNames.IS_NEW_PHONE_NUMBER],
      },
    });
    return dispatch(initiateOTP(initiateOTPForm));
  },
  recordAnalyticsPageView: () => dispatch({ type: ANALYTICS_INITIATE_OTP_START_SUCCESS }),
  recordAnalyticsNoPhonePageView: () => dispatch({ type: ANALYTICS_INITIATE_OTP_NO_PHONE }),
});

type AllProps = StateProps & DispatchProps & FormHeaderProps;
const phoneNumberValidator = composeValidators(
  checkEmptyTextField('a valid Phone Number'),
  checkValidPhoneNumber
);

const InitiateOtp = (props: AllProps) => {
  const {
    otpPhones = [],
    securityEligibilityFlow,
    submitInitiateOTP,
    recordAnalyticsPageView,
    recordAnalyticsNoPhonePageView,
  } = props;
  const history = useHistory();
  const classes = useOtpStyles(props);
  // If the user does not have the securityEligibilityFlow (e.g. in case of refresh or
  // direct navigation to this page), then redirect them to the security page
  useEffectOnce(() => {
    if (!securityEligibilityFlow) {
      history.replace(Routes.SECURITY);
    }
    if (otpPhones.length === 0) {
      recordAnalyticsNoPhonePageView();
    } else {
      recordAnalyticsPageView();
    }
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const isNewAccountOpeningOtpFlow =
    securityEligibilityFlow === SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP;

  const initialValues = {
    [InitiateOTPFieldNames.PHONE_NUMBER]: '',
    [InitiateOTPFieldNames.HOW_TO_SEND_CODE]: OTPInitiateSendValues.TEXT,
  } as const;

  return (
    <PageWithCard
      headerText={OTPText.OTP_INITIATE_HEADER}
      description={OTPText.OTP_INITIATE_DESCRIPTION}
    >
      <SubtitleText fontWeight="medium">{OTPText.NO_PHONE_NUMBER_TEXT}</SubtitleText>
      <Form
        onSubmit={(form) => {
          if (isNewAccountOpeningOtpFlow && otpPhones.length === 0) {
            history.replace(`${Routes.NAO}/5`);
          } else {
            submitInitiateOTP({
              phone_number: form[InitiateOTPFieldNames.ENTER_PHONE_NUMBER],
              oob_channel: form[InitiateOTPFieldNames.HOW_TO_SEND_CODE],
              is_new_phone_number: true,
            });
          }
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, values = {}, hasValidationErrors }) => {
          const disableSendCodeButton =
            otpPhones.length === 0
              ? hasValidationErrors
              : otpPhones.length === 0 || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                name={InitiateOTPFieldNames.ENTER_PHONE_NUMBER}
                label={OTPText.ENTER_PHONE_NUMBER}
                validate={phoneNumberValidator}
                component={RenderTelephoneField}
                parse={normalizeDashes}
                data-test={InitiateOTPFieldNames.ENTER_PHONE_NUMBER}
              />

              {otpPhones.length > 0 && (
                <Field
                  name={InitiateOTPFieldNames.HOW_TO_SEND_CODE}
                  component={RadioField}
                  options={initiateOtpRadioOptions(classes.radioButton)}
                  selectedValue={values[InitiateOTPFieldNames.HOW_TO_SEND_CODE]}
                  type="radio"
                  role="radiogroup"
                  data-test={InitiateOTPFieldNames.HOW_TO_SEND_CODE}
                />
              )}
              <BackAndNext
                primaryOnLeft
                disableNext={disableSendCodeButton}
                onNext={handleSubmit}
                loading={submitting}
                nextText={BackNextAndCancelBtn.SEND_CODE}
                onBack={() => setShowConfirmationModal(true)}
                backText={BackNextAndCancelBtn.CANCEL_GENERIC}
                nextType="submit"
                data-track-next="send-otp-code"
              />
            </form>
          );
        }}
      </Form>
      <ConfirmLeaveOtpModal
        visible={showConfirmationModal}
        date-test="confirmation-leave-modal"
        onExit={() => setShowConfirmationModal(false)}
        onConfirm={() => history.replace(`${Routes.NAO}/5`)}
        isNewAccountOpeningFlow={isNewAccountOpeningOtpFlow}
      />
    </PageWithCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InitiateOtp);
