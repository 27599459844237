import React from 'react';
import { SynchronyInfo } from '../../components/cms/blockText.constants';
import { BodyTextSmallEmphasis, InlineLinkText } from '../../components/typography/typography';
import { CustomerAddress, CustomerPhone, Name } from '../../utilities/types';
import { NewJointOwners } from '../newAccountOpening/jointOwners/typings';

const phoneStyle = { whiteSpace: 'nowrap' };

export const OTPText = {
  OTP_INITIATE_HEADER: 'Confirm your identity',
  OTP_INITIATE_DESCRIPTION:
    "For your security, we'll send you a one-time, four-digit verification code—providing you additional security to authorize certain transactions.",
  NO_PHONE_NUMBER_TEXT: (
    <>
      If you don&apos;t have a phone number due to a hearing or speech disability, call us at{' '}
      <InlineLinkText href={`tel://${SynchronyInfo.AUTH_PHONE_TEXT}`} style={phoneStyle}>
        {SynchronyInfo.AUTH_PHONE_TEXT}
      </InlineLinkText>
      .
    </>
  ),
  ENTER_PHONE_NUMBER: 'Enter Phone Number',
};

export const OTPValidateText = {
  OTP_VALIDATE_HEADER: 'Enter the code',
  NEED_ANOTHER_CODE_TEXT: 'Need another code?',
  RESEND_NOW_TEXT: 'Re-send now',
  OTP_VALIDATE_DESCRIPTION_INFO: (phoneDisplay: string) => {
    return `Please enter the code we sent you. The code has been sent to your phone number ending in ${phoneDisplay} and will expire in 10 minutes.`;
  },
};

export const OTPValidateFormLabels = {
  ENTER_CODE: 'Enter Code',
  REMEMBER_THIS_DEVICE: 'Remember This Device',
};

export const InitiateOTPFieldNames = {
  PHONE_NUMBER: 'phone_number',
  HOW_TO_SEND_CODE: 'oob_channel',
  ENTER_PHONE_NUMBER: 'enter_phone_number',
  IS_NEW_PHONE_NUMBER: 'is_new_phone_number',
};

export type InitiateOTPFieldName = typeof InitiateOTPFieldNames[keyof typeof InitiateOTPFieldNames];

export const ValidateOTPFieldNames = {
  OTP: 'otp',
  REMEMBER_DEVICE: 'rememberDevice',
};

export type ValidateOTPFieldName = typeof ValidateOTPFieldNames[keyof typeof ValidateOTPFieldNames];

export const OTPInitiateSendValues = Object.freeze({
  TEXT: 'text',
  CALL: 'call',
});

export type OTPInitiateSendValue = typeof OTPInitiateSendValues[keyof typeof OTPInitiateSendValues];

export const initiateOtpRadioOptions = (classes: string) => [
  {
    label: (
      <>
        <div className={classes}>Send code as a text message</div>
        <BodyTextSmallEmphasis data-test="send-code-label">
          Wireless and text message fees may apply from your carrier.
        </BodyTextSmallEmphasis>
      </>
    ),
    value: OTPInitiateSendValues.TEXT,
  },
  {
    label: 'Send code as a phone call',
    value: OTPInitiateSendValues.CALL,
  },
];

export const InitiateOtpNoPhone = [
  {
    name: 'No Phone Number on File',
    value: 'undefined',
  },
];

export const TRANSMIT_RESPONSE = Object.freeze({
  STATUS_SUCCESS: '0000',
  STATUS_SYSTEM_ERROR: '0001',
  STATUS_FRAUD_DEVICE: '0002',
  STATUS_CANCELLED: '0004',
  STATUS_AUTH_FAILURE: '0005',
  STATUS_SESSION_EXPIRED: '0006',
  STATUS_SOMETHING_WRONG: '0007',
  STATUS_INVALID_BSL_TOKEN: '0008',
  STATUS_REVIEWED: '0015',
  STATUS_DECLINED: '0016',
});

export const TRANSMIT_ANALYTICS_RESPONSE = Object.freeze({
  STATUS_SUCCESS: '0000_transmit_success',
  STATUS_SYSTEM_ERROR: '0001_transmit_system error',
  STATUS_FRAUD_DEVICE: '0002_transmit_fraud_device',
  STATUS_CANCELLED: '0004_transmit_cancel_otp',
  STATUS_AUTH_FAILURE: '0005_transmit_auth_failure',
  STATUS_SESSION_EXPIRED: '0006_transmit_auth_session_expires',
  STATUS_SOMETHING_WRONG: '0007_transmit_something_went_wrong',
  STATUS_INVALID_BSL_TOKEN: '0008_transmit_invalid_bsl_token',
  STATUS_REVIEWED: '0015_transmit_review',
  STATUS_DECLINED: '0016_transmit_declined',
});
export type TransmitApplicationStatus =
  | typeof TRANSMIT_RESPONSE[keyof typeof TRANSMIT_RESPONSE]
  | string;

export const MFA_STATUS = Object.freeze({
  STATUS_PASS: 'Pass',
  STATUS_FAIL: 'Fail',
});

export type SelectedJointOwnerValue = {
  address: CustomerAddress;
  customerId: string;
  dateOfBirth: string;
  email: string;
  fullName: string;
  name: Name;
  pendingApplications: boolean;
  phones: CustomerPhone[];
  taxId: string;
  noPhoneIndicator: boolean;
};

export type TransmitJointOwnersLists = {
  newJointOwners: NewJointOwners;
  selectedExistingJointOwners: SelectedJointOwnerValue[];
};

export type TransmitExistingAccountCustomerInfo = {
  first_name: string;
  middle_name: string;
  last_name: string;
  suffix: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip5: string;
  dob: string;
  ssn: string;
  phone_number: string;
  phone_type: string;
  email_id: string;
  customer_type_flag: string;
  customer_id: string;
  no_phone: boolean;
};
