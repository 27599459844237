import React, { ReactElement } from 'react';

import { SynchronyInfo } from '../../components/cms/blockText.constants';
import Paths from '../routes/routes.constants';
import i18n from '../../strings/i18n';
import { CONTACT_US_CODE_DEFAULT } from './applications.constants';

export const JointOwnerText = Object.freeze({
  ADD_JOINT_OWNER: 'Add a Joint Owner',
});

export const FoundInSystemText = Object.freeze({
  PRIMARY_OWNER_TITLE: 'We found you in our system.',
  JOINT_OWNER_TITLE: 'Found in system',
  JOINT_OWNER_COPY: (
    <span>
      This person has an existing pending application and cannot be added as a joint owner at this
      time. You may add other joint owners or continue your application as an individual account
      type and contact us at {SynchronyInfo.PHONE} after submitting your application.
    </span>
  ),
});

export type CtaText = {
  CTA_COPY: string | ReactElement<'span'>;
  CTA_HEADER: string;
  CTA_SUBCOPY?: string | ReactElement<'span'>;
};

export type CtaUrl = {
  CTA_URL: string;
  CTA_TEXT: string;
  external?: boolean;
};

export type CtaLinkData = {
  NAO_LINK: CtaUrl;
  EAO_LINK: CtaUrl;
};

export const PatriotActText = (
  <span>
    All financial institutions are required by the federal USA PATRIOT Act to obtain, verify, and
    record information that identifies each person seeking to open an account. When you apply to
    open an account, we will ask for your name, address, date of birth, Social Security Number and
    other information that will allow us to identify you.
  </span>
);

export const TryAgainApplicationText = Object.freeze({
  CTA_HEADER: 'Please try again',
  CTA_COPY: (
    <span>
      Thank you for your interest in opening an account with Synchrony Bank. To continue opening an
      account, please try again or call us at {SynchronyInfo.PHONE}.
    </span>
  ),
});

export const ExitApplicationLinkData: CtaLinkData = Object.freeze({
  EAO_LINK: {
    CTA_URL: '/accounts',
    CTA_TEXT: 'Return To Accounts Dashboard',
  },
  NAO_LINK: {
    CTA_URL: 'https://www.synchronybank.com',
    CTA_TEXT: 'Return to SynchronyBank.com',
    external: true,
  },
});

export const TryAgainApplicationLinkData: CtaLinkData = Object.freeze({
  EAO_LINK: {
    CTA_URL: Paths.EAO,
    CTA_TEXT: 'Try Again',
  },
  NAO_LINK: {
    CTA_URL: Paths.NAO,
    CTA_TEXT: 'Try Again',
  },
});

export const CancelApplicationCtaText = (bankersHoursText?: string | null): CtaText => {
  const fallBackCopy = 'Monday - Friday 7am - midnight ET, Saturday - Sunday 8am - 5pm ET';
  return {
    CTA_HEADER: 'Cancelled account opening application',
    CTA_COPY:
      'Thank you for your interest in opening an account with Synchrony Bank. Your application to open an account has been cancelled. Your information will not be stored.',
    CTA_SUBCOPY: (
      <span>
        You may also open an account by calling us at {SynchronyInfo.PHONE} during our normal hours
        of operation ({bankersHoursText || fallBackCopy}).
      </span>
    ),
  };
};

export const ContactUsCtaText = (contactUsCode = CONTACT_US_CODE_DEFAULT): CtaText => {
  const synchronyInfoPhone = SynchronyInfo.PHONE_TEXT;
  return {
    CTA_HEADER: i18n({ CONTACT_US_MESSAGE: 'CTA_HEADER' }),
    CTA_COPY: i18n({ CONTACT_US_MESSAGE: 'CTA_COPY' }),
    CTA_SUBCOPY: (
      <span>
        {i18n(
          { CONTACT_US_MESSAGE: 'CTA_SUB_COPY' },
          {
            synchronyInfoPhone,
            contactUsCode,
          }
        )}
      </span>
    ),
  };
};

export const DepositInstructionsText = Object.freeze({
  CTA_HEADER: 'Connection initiated',
  CTA_COPY: 'Your external bank connection has been initiated. Here’s what happens next:',
  CTA_COPY_LIST: [
    '1. We will make two small deposits of less than $0.25 to your external account. You can expect to see them in the next 2-3 business days.',
    '2. We will send you an email when these amounts have been deposited into your external account.',
    '3. Login to your external bank account and write down these amounts.',
    '4. Return to this site and visit External Accounts to verify your account.',
    '5. After your external account is connected, you can transfer to and from your Synchrony Bank account.',
  ],
  CTA_URL: '/accounts/external',
  CTA_BTN_TEXT: 'Done',
  AFF_CTA_URL: '/accounts',
  AFF_CTA_BTN_TEXT: 'View Account Dashboard',
});

export const PendingApplicationText: CtaText = Object.freeze({
  CTA_HEADER: 'Thanks',
  CTA_COPY: (
    <span>
      Thank you for your interest in opening an account with Synchrony Bank. You currently have 1
      pending application. Please call us at {SynchronyInfo.PHONE} to complete or cancel the
      application.
    </span>
  ),
});

// TODO: Add ctaText back as well as Object.freeze
export const PendingApplicationReviewText = (appId: string) => ({
  CTA_HEADER: 'Thank You',
  CTA_COPY: (
    <span>
      <p>We&apos;ve received your application and given it the following ID: {appId}</p>
      <br />
      <p>
        Your application is going through our review process now, which can take up to 3 business
        days. Meanwhile, if you have any questions, please give us a call at {SynchronyInfo.PHONE}{' '}
        and reference your application ID.
      </p>
    </span>
  ),
});

export const RegistrationFailedText: CtaText = Object.freeze({
  CTA_HEADER: 'Please contact us',
  CTA_COPY: (
    <span>
      We’re sorry, we can’t authenticate you at this time to register your account. Please call us
      at {SynchronyInfo.PHONE}
    </span>
  ),
});

export const CreatedWithoutOTPApplicationText: CtaText = Object.freeze({
  CTA_HEADER: 'Thank You',
  CTA_COPY: (
    <span>
      <p>Your account is created and we’ll be sending you more information about your account.</p>
      <br />
      <p>
        To access your account sooner or if you have any questions, please give us a call at{' '}
        {SynchronyInfo.OTP_PHONE_LINK}.
      </p>
    </span>
  ),
});

export const UnknownApplicationText: CtaText = Object.freeze({
  CTA_HEADER: 'Contact us',
  CTA_COPY: (
    <span>
      Unfortunately, we are unable to finalize your application at this time. To complete the
      application process, please contact us at {SynchronyInfo.PHONE}.
    </span>
  ),
});

export const ErrorOccurredText: CtaText = Object.freeze({
  CTA_HEADER: 'Unfortunately an error has occurred.',
  CTA_COPY: (
    <span>
      We are unable to process your request at this time. To continue, try again later or contact us
      at {SynchronyInfo.PHONE}.
    </span>
  ),
});

export const ProcessingApplicationText: CtaText = Object.freeze({
  CTA_HEADER: 'Thank You',
  CTA_COPY: (
    <span>
      Please call us at {SynchronyInfo.PHONE} with any further questions about your application.
    </span>
  ),
});

export const DeniedApplicationText: CtaText = Object.freeze({
  CTA_HEADER: 'Contact us',
  CTA_COPY: (
    <span>
      <p>
        Thank you for your interest in opening an account with Synchrony Bank. At this time, we
        cannot proceed with your application online.
      </p>
      <br />
      <p>
        Please call us in order to continue with your account opening at {SynchronyInfo.PHONE} and
        reference this code: {CONTACT_US_CODE_DEFAULT}.
      </p>
    </span>
  ),
});

export const DeclinedApplicationText: CtaText = Object.freeze({
  CTA_HEADER: 'Thank You',
  CTA_COPY: (
    <span>
      <p>
        Thank you for your interest in opening an account. Unfortunately, your application has been
        declined because we are either unable to verify your identity as required by the USA PATRIOT
        Act, or we are unable to confirm other information provided on your application.
      </p>
      <br />
      <p>
        If you have questions, please call one of our Bankers toll-free at {SynchronyInfo.PHONE}.
      </p>
    </span>
  ),
});

export const FailApplicationText = {
  CTA_HEADER: 'Thank You',
  CTA_COPY: (
    <span>
      Thank you for your interest in opening an account with Synchrony Bank. Our system is unable to
      process new applications at this time. To continue opening an account, try again later or call
      us at {SynchronyInfo.PHONE}.
    </span>
  ),
};

export const ForbiddenApplicationText = Object.freeze({
  CTA_HEADER: 'Thank you for your interest in Synchrony Bank.',
  CTA_COPY_MULTIPLE_CUSTOMERS: (
    <span>
      In order to continue with the account opening process, please call us at {SynchronyInfo.PHONE}
      .
    </span>
  ),
});

export const PatchFailureText = Object.freeze({
  CTA_HEADER: 'Please try again',
  CTA_COPY: (
    <span>
      Thank you for your interest in opening an account with Synchrony Bank. We are having trouble
      processing your request at this time. Please try again or call us at {SynchronyInfo.PHONE}.
    </span>
  ),
});

export const PersonalInformationText = Object.freeze({
  CTA_HEADER: `Thanks for choosing ${SynchronyInfo.BANKNAME}: let's get started.`,
});

export const MoreAboutYouText = Object.freeze({
  CTA_HEADER: (firstName: string) => `Tell us a little more about yourself, ${firstName}:`,
  PAGE_TITLE: `Tell us a little more about yourself - ${SynchronyInfo.BANKNAME}`,
});

export const SelectAProductText = Object.freeze({
  CTA_HEADER: 'What kind of bank account are you looking for?',
});

export const AddJointOwnerText = Object.freeze({
  CTA_HEADER: 'Who else should be added as an owner on this account?',
});

export const ReviewProductSelectionText = Object.freeze({
  CTA_HEADER: 'Does this look right?',
});

export const TermsAndConditionsText = Object.freeze({
  CTA_HEADER: 'Please review the terms and conditions.',
  CTA_COPY: 'Click each box below to confirm your agreement or acknowledgement as indicated.',
});

export const ChooseFundingText = Object.freeze({
  CTA_HEADER: "Let's add some money to your new account.",
});

export const AddNewExternalAccountOption = Object.freeze({
  CTA_HEADER: 'Add a New External Account',
  CTA_COPY: "Select the method you'd like to use to add a new external account",
});

export const FundAmountText = Object.freeze({
  CTA_HEADER: 'How much would you like to deposit?',
});

export const FundingReviewText = Object.freeze({
  CTA_HEADER: 'Review your funding.',
});

export const FundingConfirmationText = Object.freeze({
  CTA_HEADER: 'Congratulations, you’re all set.',
  CTA_CHECK_HEADER: 'Almost There...',
});

export const ApplicationStatuses = {
  CREATED: 'CREATED',
  REVIEW: 'REVIEW',
  OTP: 'OTP',
  PENDING: 'PENDING',
  UNKNOWN: 'UNKNOWN',
  DENIED: 'DENIED',
} as const;

export const HeaderRequest = Object.freeze({
  channelId: 'banking-web',
});

export type ApplicationStatus = typeof ApplicationStatuses[keyof typeof ApplicationStatuses];

export default {};
